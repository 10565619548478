.root_lobby {
  margin: 0.75rem;
}
.logo_quiz {
  /* width: 11.88rem; */
  height: 6rem;

  /* border-radius: 0.625rem; */
  /* margin: 0 auto 0.69rem; */
  margin: 0 auto
}
.logo_quiz video {
    height: 100%;
  }
.root_lobbytimer {
  margin-bottom: 0.88rem;
}
.lbl_lobbytimer {
  font-size: 1rem;
  font-weight: 600;
  color: #fedb00;
}
.val_lobbytimer {
  font-weight: bold;
  font-size: 2rem;
  color: #fedb00;
  letter-spacing: 0;
}
.root_lobbyrules {
  background: rgba(255, 255, 255, 0.1);
  border: 2px solid rgba(255, 255, 255, 0.2);
  margin-bottom: 1.13rem;
}
.hdr_lobbyrules {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0.5rem 0.88rem;
  border-bottom: 1px solid #eeeeee1a;
}
.lbl_lobbyrules {
  font-weight: bold;
  font-size: 0.875rem;
  color: #ffffff;
}
.expand_lobbyrules {
  position: relative;
  font-weight: 600;
  font-size: 0.75rem;
  color: #fedb00;
  margin-right: 1rem;
}
.body_lobbyrules {
  padding: 0.63rem 1.13rem 0.75rem;
}
.txt_lobbyrules {
  font-weight: 500;
  font-size: 0.875rem;
  color: #ffffff;
  text-align: left;
  padding-bottom: 0.75rem;
}
.txt_lobbyrules:last-child {
  padding-bottom: 0;
}
.txt_lobbyrules a {
  color: #ffffff;
}
.root_lobbyprizes {
  background: rgba(255, 255, 255, 0.1);
  border: 2px solid rgba(255, 255, 255, 0.2);
  padding: 0.69rem 0 0.75rem 0;
  margin-bottom: 1.13rem;
}
.hdr_lobbyprizes {
  font-weight: bold;
  font-size: 0.875rem;
  color: #ffffff;
  margin-bottom: 0.75rem;
}
.body_lobbyprizes {
  display: flex;
  justify-content: space-around;
}
.item_lobbyprizes {
  height: 5rem;
  width: 5rem;
  background: #ffffff;
}
.root_enteremaillobby {
  background: rgba(255, 255, 255, 0.1);
  border: 2px solid rgba(255, 255, 255, 0.2);
  padding: 0.81rem 0.63rem;
}
.hdr_enteremaillobby {
  font-size: 0.75rem;
  color: #ffffff;
  text-align: left;
  margin-bottom: 0.69rem;
}
.arrow_up {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;

  border-bottom: 5px solid #fedb00;

  position: absolute;
  right: -1rem;
  top: 0.4rem;
}
.arrow_down {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #fedb00;

  position: absolute;
  right: -1rem;
  top: 0.4rem;
}
.err_email {
  margin-top: 0.25rem;
  text-align: left;
  color: #eb1414;
  font-size: 0.8135rem;
  font-weight: 600;
}
.btn_joinQuiz {
  background: #fedb01;
  width: 12rem;
  margin: 0.5rem auto 0;
  color: #161619;
  padding: 0.75rem 0;
  font-weight: bold;
  border: 1px solid #979797;
}
.btn_quizJoined {
  opacity: 0.9;
  background: #e7e7e7;
  border: 1px solid #979797;
  border-radius: 43.65px;
  border-radius: 43.65px;
  width: 12rem;
  margin: 0.5rem auto 0;
  padding: 0.5rem 0;
  font-weight: bold;
  color: #161619;
}
.txt_lobbyCountry {
  font-size: 1rem;
  font-weight: 500;
  margin-top: 0.8rem;
  color: #ffffff;
}
.hdr_winnerCard {
  font-weight: 600;
  font-size: 1.25rem;
  color: #ffffff;
  margin-bottom: 2px;
}
.reward_winnerCard {
  font-weight: bold;
  font-size: 1.75rem;
  color: #f0d50a;
  letter-spacing: 0;
  margin-bottom: 1rem;
}
.prize_winnerCard {
  height: 6rem;
  width: 6rem;
  /* border-radius: 50%; */
  background: #ffffff;
  border: 0.35rem solid #f0d50a;
  padding: 0.25rem;
  margin: 0 auto 0.375rem auto;
}
.prize_winnerCardimg {
    max-width: 100%;
    max-height: 100%;
  }
.score_winnerCard {
  font-weight: bold;
  font-size: 1.125rem;
  color: #ffffff;
  margin-bottom: 0.375rem;
}
.rank_winnerCard {
  font-weight: bold;
  font-size: 1.5rem;
  color: #ffffff;
  margin-bottom: 1.5rem;
}
.hdr_lostCard {
  font-weight: bold;
  font-size: 1.75rem;
  color: #f0d50a;
  margin-bottom: 2px;
}
.subhdr_lostCard {
  font-weight: 600;
  font-size: 1.25rem;
  color: #ffffff;
  margin-bottom: 1rem;
}
.root_leaderboard {
  border: 1px solid rgba(255, 255, 255, 0.25);
}
.hdr_leaderboard {
  font-weight: 600;
  font-size: 1rem;
  color: #efdb1a;
  background: rgba(255, 255, 255, 0.1);
  padding: 1rem 0;
}
.card_leaderboard {
  display: flex;
  flex: 1;
  flex-direction: row;
  padding: 0.5rem 0;
}
.even_row {
  background: #ffffff0d;
}
.serial_leaderboard {
  font-weight: 600;
  font-size: 0.875rem;
  color: #8c9196;
  flex: 0.1;
  align-self: center;
}
.user_leaderboard {
  flex: 0.85;
  text-align: left;
  align-items: center;
  align-self: center;
}
.name_leaderboard {
  font-weight: 600;
  font-size: 0.8125rem;
  color: #ffffff;
}
.points_leaderboard {
  font-weight: 600;
  font-size: 0.8125rem;
  color: #fedb00;
}
.won_leaderboard {
  font-weight: 600;
  font-size: 0.8125rem;
  color: #fedb00;
  align-self: center;
}
.root_getintouch {
  background: rgba(255, 255, 255, 0.07);
  display: flex;
  padding: 1rem 0.75rem;
  margin-bottom: 1rem;
}
.img_getintouch {
  display: none;
  background: #464646;
  height: 1.88rem;
  width: 2.5rem;
  border-radius: 50%;
  margin-right: 0.75rem;
  align-self: center
}
.img_getintouch img {
    max-width: 100%;
  }
.txt_getintouch {
  font-size: 1rem !important;
  font-weight: 500;
  font-size: 0.8125rem;
  color: #ffffff;
}
.root_enteremail {
  background: rgba(0, 0, 0, 0.2);
  border: 1px solid rgba(255, 255, 255, 0.2);
  margin-bottom: 0.75rem;
  padding: 0.75rem 0.63rem;
}
.hdr_enteremail {
  font-weight: bold;
  font-size: 0.8325rem;
  color: #ffffff;
  text-align: left;
  margin-bottom: 0.25rem;
}
.desc_enteremail {
  font-weight: 600;
  font-size: 0.75rem;
  color: #cccccc;
  text-align: left;
  margin-bottom: 0.81rem;
}
.body_enteremail {
  display: flex;
  height: 2.38rem;
}
.input_enteremail {
  border: 1px solid #cccccc;
  background: none;
  padding: 0.45rem;
  font-size: 1rem;
  width: 70%;
  color: #ffffff;
}
.submit_enteremail {
  background: #f0d50a;
  padding-top: 0.5rem;
  width: 30%;
}
.root_results {
  margin: 0.75rem;
}
.root_awaitingresults {
  color: #ffffff;
  margin-top: 8rem;
}
.img_flag {
  height: 1.19rem;
  width: 1.19rem;
  margin-right: 1rem;
  align-self: center;
}
.playContainer {
  background: #222222;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
  height: 100vh;
  widows: 100vw;
}
.playHeader {
  position: fixed;
  top: 0;
  width: 100%;
  max-width: 470px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.64rem 1.18rem
}
.playHeader p {
    font-family: Barlow;
    font-size: 1.36rem;
    color: #ffffff;
    letter-spacing: 0;
    text-align: center;
    line-height: 1.21ream;
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
  }
.playHeader span {
    font-family: Barlow;
    font-size: 0.86rem;
    color: #8c9196;
    letter-spacing: 0;
    text-align: center;
    line-height: 17px;
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
  }
.playHeader .round {
    width: 3.14rem;
    height: 3.14rem;
    border: 4px solid #fedb00;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
.videoSection {
  /* height: 21.25rem; */
  height: 16.25rem;
  margin-top: 4.25rem
}
.videoSection video {
    outline: none !important;
  }
.qstnCont {
  border: 1px solid rgba(255, 255, 255, 0.15);
  display: flex;
  align-items: center;
  opacity: 0.9;
  background: rgba(34, 34, 34, 0.9);
  /* padding: 0.93rem; */
  height: 4.38rem;
  z-index: 99999
}
.qstnCont p {
    margin: 0 0.93rem;
    width: 100%;
    font-family: Barlow;
    font-size: 1.14rem;
    color: #fedb00;
    text-align: center;
    line-height: 22px;
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  }
.optionCont {
  margin-top: 1.18rem;
  padding: 0 2.71rem;
  height: 14rem;
  overflow-y: scroll;
}
.options {
  background: rgba(255, 255, 255, 0.1);
  border: 2px solid rgba(255, 255, 255, 0.2);
  border-radius: 20px;
  border-radius: 20px;
  height: 2.86rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0.86rem
}
.options.active {
    background: #fedb00;
    border: 2px solid rgba(255, 255, 255, 0.2)
  }
.options.active p {
      color: #222222;
    }
.options.correct {
    background: #4ab78e
  }
.options.correct p {
      color: #ffffff;
    }
.options.incorrect {
    background: red
  }
.options.incorrect p {
      color: #ffffff;
    }
.options.answered {
    background-image: linear-gradient(179deg, #48cfad 0%, #19a784 100%);
    border: 2px solid rgba(255, 255, 255, 0.2);
  }
.options p {
    font-family: Barlow;
    font-weight: bold;
    font-size: 1.14rem;
    color: #ffffff;
    letter-spacing: 0;
  }
.quiz_placeholderText {
  font-family: "Barlow";
  font-weight: bold;
  color: #fedb00;
  font-size: 1.14rem;
  margin-top: 4rem;
}
.sliderclass {
  margin-top: -4rem;
  z-index: 9999999;
}
*:focus {
  outline: 0;
  outline: none;
}
.btn_unmute {
  background: #f0d50a;
  padding: 0.25rem;
  color: #161619;
  width: 6rem;
  margin: 1rem auto;
  font-weight: bold;
  font-size: 1.25rem;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
img {
  max-width: 100%;
  vertical-align: middle;
}
#root {
  position: relative;
}
:root {
  font-size: 4vw;
  overflow: hidden;
}
body {
  background: black;
  font-family: "Barlow", sans-serif;
}
@media screen and (min-width: 470px) {
  #root {
    max-width: 470px;
    position: relative;
  }
  :root {
    font-size: 15px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
  }
}
.snackBarCont {
  background: #fedb00;
  padding: 0.5rem 1.14rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  bottom: 4.25rem;
  width: 100%
}
.snackBarCont.snackHide {
    display: none;
  }
.snackBarCont .snackCont {
    text-align: left;
    padding-left: 1rem;
    width: 75%
  }
.snackBarCont .snackCont p {
      font-family: "Barlow";
      font-size: 1rem;
      color: #666666;
      font-weight: 500;
    }
.snackBarCont h6 {
    font-family: "Barlow";
    font-weight: bold;
    font-size: 1rem;
    color: #222222;
    line-height: 1rem;
    padding-bottom: 0.3rem;
  }
.snackBarCont p {
    font-family: "Barlow";
    font-size: 1.14rem;
    color: #666666;
  }
.snackBarCont .gotIT {
    cursor: pointer;
  }
.snackBarCont .gotIT p {
      font-family: "Barlow";
      font-size: 1.14rem;
      color: #222222;
    }
